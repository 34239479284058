var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('l-menu',{ref:"menu",attrs:{"menu":_vm.menu,"menu-setting":{
      isLogo: true,
      isMenuSub: true,
      toggleSystem: false,
      iconColor: '#ffffff',
      isCollapsed: false
    },"sub-menu-key":"children"},on:{"menu-click":_vm.menuClick}}),_c('div',{staticClass:"nav-right"},[_c('div',{staticClass:"p-header"},[_c('div',{staticClass:"p-header-right"},[_c('l-dropdown',[_c('div',[_vm._v("魔方创拼室"),_c('l-icon',{attrs:{"type":"down"}})],1),_c('template',{slot:"menuItem"},[_c('l-dropdown-item',{key:"1",attrs:{"is-drop":true}},[_c('div',{on:{"click":_vm.toLogin}},[_vm._v("退出登录")])])],1)],2)],1)]),_c('div',{staticClass:"p-body"},[_c('l-tabs',{staticStyle:{"max-width":"100%"},attrs:{"type":"editable-card","hide-add":true},on:{"edit":_vm.onEdit,"change":_vm.changeTabs},model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},_vm._l((_vm.tabs),function(item){return _c('l-tab-pane',{key:item.url,attrs:{"tab":item.title,"closable":item.closable}})}),1),_c('div',{staticClass:"p-content"},[_c('l-page-header',{attrs:{"title":_vm.$route.meta.title,"sub-title":_vm.$route.meta.subtitle}}),_c('div',{staticClass:"l-p-24 l-pt-0"},[_c('keep-alive',[_c('l-config-provider',{attrs:{"locale":_vm.locale}},[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1)],1),_c('l-config-provider',{attrs:{"locale":_vm.locale}},[(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }