export default [
  {
    name: '数据面板',
    path: '/statistics/dataStatistics',
    component: () => import('../views/statistics/dataStatistics'),
    meta: {
      title: '数据面板'
    }
  },
  {
    name: '事件分析',
    path: '/statistics/eventAnalysis',
    component: () => import('../views/statistics/eventAnalysis'),
    meta: {
      title: '事件分析'
    }
  },
]
