<template>
  <div class="container">
    <l-menu
      ref="menu"
      :menu="menu"
      :menu-setting="{
        isLogo: true,
        isMenuSub: true,
        toggleSystem: false,
        iconColor: '#ffffff',
        isCollapsed: false
      }"
      sub-menu-key="children"
      @menu-click="menuClick"
    />
    <div class="nav-right">
      <div class="p-header">
        <div class="p-header-right">
          <l-dropdown>
            <div>魔方创拼室<l-icon type="down" /></div>
            <template slot="menuItem">
              <l-dropdown-item key="1" :is-drop="true">
                <div @click="toLogin">退出登录</div>
              </l-dropdown-item>
            </template>
          </l-dropdown>
        </div>
      </div>
      <div class="p-body">
        <l-tabs
          v-model="activeKey"
          type="editable-card"
          :hide-add="true"
          style="max-width: 100%;"
          @edit="onEdit"
          @change="changeTabs"
        >
          <l-tab-pane
            v-for="item in tabs"
            :key="item.url"
            :tab="item.title"
            :closable="item.closable"
          />
        </l-tabs>
        <div class="p-content">
          <l-page-header
            :title="$route.meta.title"
            :sub-title="$route.meta.subtitle"
          />
          <div class="l-p-24 l-pt-0">
            <keep-alive>
              <l-config-provider :locale="locale">
                <router-view v-if="$route.meta.keepAlive" />
              </l-config-provider>
            </keep-alive>
            <l-config-provider :locale="locale">
              <router-view v-if="!$route.meta.keepAlive" />
            </l-config-provider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
export default {
  data() {
    return {
      locale: zhCN,
      activeKey: '/',
      tabs: [
        { title: '首页', url: '/', closable: false }
      ],

      include: ['/']
    }
  },

  computed: {
    ...mapState({
      menu: state => state.login.menuList
    })
  },

  watch: {
    $route() {
      if (this.type !== 'click') {
        this.addTabs()
      }
    }
  },

  mounted() {
    this.$store.dispatch('login/getMenu')
    setTimeout(() => {
      this.addTabs()
    }, 0)
  },

  methods: {
    menuClick(e) {
      this.$router.push(e.url)
    },
    onEdit(targetKey, action) {
      this[action](targetKey)
    },
    remove(key) {
      this.tabs.some((item, index) => {
        if (item.url === key) {
          this.tabs.splice(index, 1)
          return true
        }
      })
    },
    changeTabs(type) {
      if (this.activeKey !== this.$route.path) {
        this.type = type || ''
        this.$toPage(this.activeKey)
      }
    },
    addTabs() {
      if (this.$route.path === '/') {
        return
      }
      const exist = this.tabs.find(item => item.url === this.$route.path)
      if (exist) {
        this.activeKey = this.$route.path
        this.changeTabs()
        return
      }
      this.tabs.push({
        title: this.$route.name,
        url: this.$route.path,
        closable: true
      })
      this.activeKey = this.$route.path
      this.changeTabs()
    },
    toLogin() {
      sessionStorage.removeItem('userInfo')
      sessionStorage.removeItem('sessionKey')
      window.location.href = '/login.html'
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  height: 100vh;
  min-width: 1440px;
  .nav-left {
    display: flex;
    flex-direction: column;
    width: 200px;
    background: #001529;
    transition: 0.15s;
    &.breviary {
      width: 80px;
    }
    .nav-left-logo {
      height: 60px;
      line-height: 60px;
      color: #1890ff;
      font-size: 24px;
      padding: 0 24px 0;
      cursor: pointer;
      .nav-logo {
        display: inline-block;
        width: 100px;
        margin-left: 12px;
        user-select: none;
      }
    }
    .nav-left-menu {
      flex: 1%;
    }
  }
  .nav-right {
    flex: 1%;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    .p-header {
      height: 60px;
      max-height: 60px;
      min-height: 60px;
      padding: 0 20px;
      box-shadow: 0 2px 8px 0 #e6e6e6;
      .p-header-item {
        display: inline-block;
        min-width: 100px;
        line-height: 60px;
        cursor: pointer;
        vertical-align: top;
        text-align: center;
      }
      .p-header-right {
        float: right;
        line-height: 60px;
        display: flex;
        .p-header-right-icon {
          line-height: 60px;
          margin-right: 20px;
          cursor: pointer;
          &:hover {
            color: #1890ff;
          }
        }
      }
    }
    .p-body {
      flex: 1%;
      box-sizing: border-box;
      padding: 20px;
      background: #f2f5f6;
      .p-content {
        background: #ffffff;
        height: calc(100% - 40px);
        position: relative;
      }
    }
  }
}
</style>

<style lang="scss">
.container {
  .nav-left {
    .nav-left-logo {
      .nav-logo {
        background-image: url('../../assets/images/logo.png');
      }
    }
  }
}

.ant-form {
  margin-bottom: 30px !important;

  .ant-select{
    width: 160px;
  }

  .ant-form-item {
    vertical-align: middle;
  }
}

.ant-tooltip {
  max-width: 416px !important;

  img {
    width: 400px !important;
    max-width: 400px !important;
  }
}
</style>
