import $store from '../store'

export default {
  init(Vue) {
    Vue.directive('auth', (el, binding) => {
      if ($store.state.login.authCache) {
        if ($store.state.login.authCache.indexOf(binding.value) !== -1) {
          return
        }
      }

      if (el.className.indexOf('is-disabled') === -1) {
        el.className = el.className + ' is-disabled'
      }
      el.setAttribute('auth-code', binding.value)
      el.setAttribute('disabled', 'disabled')
      el.setAttribute('title', '没有此功能权限')
    })
  }
}
