// 获取url带的参数
function getParam(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  const tmp = window.location.href.split('?')
  if (tmp.length <= 1) {
    return null
  }
  const r = tmp[tmp.length - 1].match(reg)

  if (r != null) {
    return unescape(r[2])
  } else {
    return null
  }
}
/**
 * 把深层级的数组,平铺成一个单层数组
 * @param {*} arr 需要平铺的数组
 * @param {*} childrenKey 子节点的key
 * @returns 只有单层的数组
 */
function flatDeep(arr, childrenKey) {
  return arr.reduce((acc, val) => {
    return acc.concat(
      (val[childrenKey] && val[childrenKey].length > 0) ?
        (acc.push(val) && flatDeep(val[childrenKey], childrenKey)) :
        [val]
    )
  }, [])
}

// 日期格式化
function formatDate(date, format) {
  if (!date) {
    return '-'
  }
  if (!(date instanceof Date)) {
    date = new Date(date)
  }
  const paddNum = function(num) {
    num += ''
    return num.replace(/^(\d)$/, '0$1')
  }
  const AmOrPm = function(num) {
    let text = ''
    if (num > 11) {
      text = '下午'
      num = num - 12
    } else {
      text = '上午'
    }
    num += ''
    return text + ' ' + num.replace(/^(\d)$/, '0$1')
  }
  // 指定格式字符
  const cfg = {
    yyyy: date.getFullYear(), // 年 : 4位
    yy: date.getFullYear().toString().substring(2), // 年 : 2位
    M: date.getMonth() + 1, // 月 : 如果1位的时候不补0
    MM: paddNum(date.getMonth() + 1), // 月 : 如果1位的时候补0
    d: date.getDate(), // 日 : 如果1位的时候不补0=
    dd: paddNum(date.getDate()), // 日 : 如果1位的时候补0
    hh: paddNum(date.getHours()), // 时
    mm: paddNum(date.getMinutes()), // 分
    ss: paddNum(date.getSeconds()), // 秒
    a: AmOrPm(date.getHours()) // 上下午+时间
  }
  format || (format = 'yyyy-MM-dd hh:mm:ss')
  return format.replace(/([a-z])(\1)*/ig, function(m) {
    return cfg[m]
  })
}
/**
 * 防抖
 * @param {*} func func是传入的函数
 * @param {*} wait wait是等待时间（毫秒）
 */
function _debounce(func, wait) {
  let timeout
  return () => {
    const args = arguments // 能接受到 e 参数
    if (timeout) clearTimeout(timeout)

    const callNow = !timeout
    timeout = setTimeout(() => {
      timeout = null
    }, wait)

    if (callNow) func.apply(this, args)
  }
}

/**
 * 移动按钮是否显示
 * @param {*} index 当前索引
 * @param {*} tableData 表格数据
 * @param {*} lock 是否锁定前 x 个不可操作的项
 */
// 是否显示上移按钮
function isUp(index, tableData, lock = 0) {
  if (tableData.page === 1) {
    return index > (1 + lock)
  }
  return true
}
// 是否显示下移按钮
function isDown(index, tableData, lock = 0) {
  if (tableData.page === 1) {
    let flag = true
    if (tableData.page === (Math.floor(tableData.total / tableData.size) + 1)) {
      flag = index < tableData.list.length
    }
    return flag && (index > (0 + lock))
  }
  if (tableData.page === (Math.floor(tableData.total / tableData.size) + 1)) {
    return index < tableData.list.length
  }
  return true
}

/**
 * 字符串按位数切割, 长度过长的字符串前面显示...
 * @param {*} str 切割字符串
 * @param {*} num 切割位数, 默认是 8
 */
function cutFront(str = '', num = 8) {
  const textNum = num
  const strLength = str.length
  if (strLength > textNum) {
    str = '...' + str.substring(strLength - textNum, strLength)
  }
  return str
}

export const debounce = _debounce
export default {
  getParam,
  flatDeep,
  formatDate,
  debounce: _debounce,
  isUp,
  isDown,
  cutFront
}
