import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from './auth'
import magicLater from './magicLater'
import statistics from './statistics'

Vue.use(VueRouter)
// 修复路由重复报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index'),
    meta: {
      title: '首页'
    }
  }
]

const customRouter = [
  auth,
  magicLater,
  statistics
]

function _extends(r) {
  r.forEach(item => {
    routes.push(item)
  })
}

customRouter.forEach(item => {
  _extends(item)
})

const router = new VueRouter({
  routes
})

export default router
