export default [
  {
    name: '主题管理',
    path: '/magicLater/theme',
    component: () => import('../views/magicLater/theme'),
    meta: {}
  },
  {
    name: '案例管理',
    path: '/magicLater/caseManage',
    component: () => import('../views/magicLater/caseManage'),
    meta: {}
  },
  {
    name: '颜色库管理',
    path: '/magicLater/colourManage',
    component: () => import('../views/magicLater/colourManage'),
    meta: {}
  },
  {
    name: '首页轮播图管理',
    path: '/magicLater/viewpagerManage',
    component: () => import('../views/magicLater/viewpagerManage'),
    meta: {}
  },
  {
    name: '用户管理',
    path: '/magicLater/userManage',
    component: () => import('../views/magicLater/userManage'),
    meta: {}
  },
  {
    name: 'AI拼图',
    path: '/magicLater/aiPuzzleManage',
    component: () => import('../views/magicLater/aiPuzzleManage'),
    meta: {}
  },
  {
    name: '玩家案例',
    path: '/magicLater/playerCaseManage',
    component: () => import('../views/magicLater/playerCaseManage'),
    meta: {}
  },

]
