export default [
  {
    name: '系统列表',
    path: '/auth-app/list',
    component: () => import('../views/auth/auth-app/list'),
    meta: {
      title: '系统列表'
    }
  },
  {
    name: '系统实例',
    path: '/auth-app-instance/list',
    component: () => import('../views/auth/auth-app-instance/list'),
    meta: {
      title: '系统实例'
    }
  },
  {
    name: '菜单列表',
    path: '/auth-menu/list',
    component: () => import('../views/auth/auth-menu/list'),
    meta: {
      title: '菜单列表'
    }
  },
  {
    name: '资源列表',
    path: '/auth-resources/list',
    component: () => import('../views/auth/auth-resources/list'),
    meta: {
      title: '资源列表'
    }
  },
  {
    name: '用户列表',
    path: '/auth-user/list',
    component: () => import('../views/auth/auth-user/list'),
    meta: {
      title: '用户列表'
    }
  },
  {
    name: '用户实例',
    path: '/auth-app-user-instance/list',
    component: () => import('../views/auth/auth-app-user-instance/list'),
    meta: {
      keepAlive: true,
      parent: '/auth-user/list',
      title: '用户实例'
    }
  },
  {
    name: '实例管理',
    path: '/auth-app-user-instance/edit',
    component: () => import('../views/auth/auth-app-user-instance/edit'),
    meta: {
      keepAlive: true,
      parent: '/auth-app-user-instance/list'
    }
  },
  {
    name: '角色列表',
    path: '/auth-role/list',
    component: () => import('../views/auth/auth-role/list'),
    meta: {
      title: '角色列表'
    }
  },
  {
    name: '角色管理',
    path: '/auth-role/edit',
    component: () => import('../views/auth/auth-role/edit'),
    meta: {
      keepAlive: true,
      parent: '/auth-role/list',
      title: '角色管理'
    }
  },
  {
    name: '集团列表',
    path: '/auth-company/list',
    component: () => import('../views/auth/auth-company/list'),
    meta: {
      title: '集团列表'
    }
  },
  {
    name: '登录日志',
    path: '/auth-login-log/list',
    component: () => import('../views/auth/auth-login-log/list'),
    meta: {
      title: '登录日志'
    }
  },
  {
    name: '参数列表',
    path: '/auth-config/list',
    component: () => import('../views/auth/auth-config/list'),
    meta: {
      title: '参数列表'
    }
  }
]
